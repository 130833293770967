<template>
  <div class="members">
    <div class="title">Members of the Westmount Volunteer Fire Department</div>
    <div class="content">
      <b-row no-gutters>
        <div v-for="member in memberList" :key="member.id">
          <div class="member-card" style="max-width: 540px;">
            <b-col class="member-card" xs={12} sm={5} md={2} lg={2}>
              <div>
                <b-img fluid
                       :src="getImgUrl(member.imageCode)"
                       width="100%"
                />
                <div class="name">{{`${member.firstName} ${member.lastName}`}}</div>
                <div class="rank">{{member.rank}}</div>
                <div class="year-joined">{{member.yearJoined}}</div>
              </div>
            </b-col>
          </div>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import json from "../json/members.json";

export default {
  data() {
    return {
      memberList: json
    };
  },

  methods: {
    getImgUrl(pic) {
      return require("../assets/members/" + pic + ".jpg");
    }
  }
};
</script>

<style>
.members {
  width: 100%;
}

.title {
  width: 100%;
  font-weight: bold;
  font-size: 2em;
  margin: 0 0 5px 10px;
}

.content {
  width: 80%;
  margin: 0 auto 1em auto;
}

.member-card {
  margin: 0.25em;
  border: 1px solid #aaa;
  padding: 5px;
  box-shadow: 2px 4px #ffe8b3;
}
.member-card .hover {
  background-color: #aaaaaa;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: table;
}
.member-card .hover .cell {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: #fff;
  font-size: 2.5em;
  opacity: 1.0;
}
.member-card .image {
  border: 1px solid #000;
  width: 100%;
}
.member-card .name {
  color: #000;
  text-align: center;
}
.member-card .rank {
  color: #777;
  text-align: center;
}
.member-card .year-joined {
  color: #777;
  text-align: center;
}
</style>
